import api from './base';

export default{
  async adminLogin(payload) {
    const result = await api().post('/auth/authorSignin', payload);
    return result;
  },
  async studentLogin(payload) {
    const result = await api().post('/auth/studentSignin', payload);
    return result;
  },
}