<template>
<div>
  <div class="background"></div>
  <div class="gradient"></div>
  <div class="container-login" id="login">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-6 col-md-5 col-lg-4">
        <transition name="slide-right" appear>
          <div class="card">
            <div class="card-body">
              <div class="white-text">
                <h1 class="font-weight-bold white-text">AIM UES Publishing</h1>
                <hr class="hr-light">
                <p>
                  Öğrencilerimiz basılı olarak alacakları haftalık çalışma kağıtlarını mevcut hafta
                  içerisinde temin edememeleri veya kaybetmeleri durumunda en son edindiği
                  çalışma kağıdının ilk sayfasında bulunan login koduyla
                  <a href="https://aim.uespublishing.com">aim.uespublishing.com</a> üzerinden o haftanın
                  çalışma kağıdına erişebilirler. Yeni eğitim öğretim yılında tüm öğrencilerimize
                  başarılar dileriz.
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="col-sm-5 col-md-4 col-lg-3">
        <transition name="slide-left" appear>
          <div class="card">
            <div class="card-body">
              <h3 class="white-text text-center">Ues Publishing AIM</h3>
              <hr class="hr-light">
              <form @submit.prevent="login">
                <div class="input-group mt-5">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fa fa-sign-in fa-2x"></i></div>
                  </div>
                  <input type="text" class="form-control" placeholder="Login Code" v-model="loginCode">
                </div>
                <div class="text-center">
                  <div class="mt-4">
                    <button class="btn btn-outline-light mb-2" type="submit" >Activate</button>
                  </div>
                  <div class="mb-3">
                    <a href="#" @click="visibleModal = true">Şifremi Unuttum!</a>
                  </div>
                  <a-modal v-model="visibleModal" title="Destek Hattı" :footer="null">
                    <div class="text-center">
                      <h6><strong>Center:</strong> 0850 550 50 90</h6>
                      <h6><strong>E-mail:</strong> support@eltturkey.com</h6>
                    </div>
                  </a-modal>
                  <hr class="hr-light">
                  <div class="row justify-content-center mt-2">
                      <a class="mr-5" href="https://twitter.com/elt_union" target="_blank">
                        <a-icon type="twitter" class="icon-style"></a-icon>
                      </a>
                      <a href="https://www.instagram.com/eltunion/" target="_blank">
                        <a-icon type="instagram" class="icon-style"></a-icon>
                      </a>
                      <a class="ml-5" href="https://www.facebook.com/eltunion" target="_blank">
                        <a-icon type="facebook" class="icon-style"></a-icon>
                      </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import authenticationService from '@/services/authentication';
import tools from '@/tools/cookie';
export default {
  data(){
    return {
      visibleModal: false,
      loginCode: '',
      visible: true
    }
  },
  beforeMount() {
    this.autoLogin();
  },
  methods:{
    async autoLogin() {
      if (!this.$route.query.code) return;
      const getCode = this.$route.query.code;
      this.loginCode = getCode;
      this.login();
    },
    async login() {
      const fixCode = this.loginCode.split("i").join("I").toUpperCase();
      if(this.loginCode !== '') {
        const result = await authenticationService.studentLogin({
          userName: fixCode,
          password: fixCode,
        });
        if(result.data) {
          tools.cookie.set('studentToken', result.data.token, 1);
          tools.cookie.set('adminToken', '', 0);
          this.$router.push({name: 'StudentHome'});
          this.$store.commit('setAuthenticationRole', 'Student');
        } else {
          this.$message.error('Username does not match!');
          this.loginCode = '';
        }
      } else {
        this.$message.error('You need input a login code!');
      }
      
    }
  }
}
</script>
<style scoped>
.background {
  background: url(/assets/img/student-login-background.jpg) no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

.gradient {
  background: -webkit-linear-gradient(45deg, rgba(83, 125, 210, 0.4), rgba(0, 70, 70, 0.4) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
}

.container-login{
  padding: 0px 20px 0px 20px;
  position: absolute;
  bottom: 0;
  top: 0;
}

.row {
  height: 100%;
}

.card {
  background-color: rgba(0, 0, 0, 0.6);
}

.white-text {
  color: #FFF!important;
}

.hr-light {
  background-color: #fff;
  height: 1px;
}

.input-group-text {
  background: transparent;
  color: #FFF;
  border: none;
}
.form-control {
  background-color: transparent;
  color: #FFF;
  border: none;
  border-bottom: #FFF 1px solid;
}

.form-control:focus {
  color: #FFF;
  background-color: transparent;
  border: none;
  box-shadow: unset;
  border-bottom: #80bdff 1.2px solid;
}

::placeholder {
  color: #b9b4b4;
  opacity: 1;
}

.icon-style {
  color: white;
  font-size: 30px;
}

.slide-right-enter-active {
	animation: slide-right 1s linear normal;
  transition: opacity 1s;
}
.slide-right-enter {
  opacity: 0;
}
@keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateZ(0px);
  }
}

.slide-left-enter-active {
	animation: slide-left 1s linear normal;
  transition: opacity 1s;
}
.slide-left-enter {
  opacity: 0;
}
@keyframes slide-left {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateZ(0px);
  }
}

</style>